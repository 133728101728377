import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { scid_yesno, scid_ynmb, mini_not_completed_reason,
  mini_q_a, mini_q_b, mini_scale1, mini_scale2 } from '../variables/assessments/scid.js'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import StepperSCID from './stepper_SCID'

class Scid2 extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          completed_status: false,
          edit_status: false,
          message: '',
          appt_id: 0,
          start_time: Math.floor(new Date().getTime()/1000),
          next_step: 'scid3',
          c13: 0,
          c13_mild: 0,
          c13_moderate: 0,
          c13_severe: 0,
          c35: 0,
          c35_mild: 0,
          c35_moderate: 0,
          c35_severe: 0,
          d16: 0,
          d22: 0,
          d28: 0,
          d37: 0,
      }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getAssmProcess()
    //console.log(this.state.start_datetime)
  }

  getAssmProcess = () => {
    if(this.props.match.params.task == 'edit') {
      this.getData('edit')
    } else {
      // Initial MINI form to database //
      axios({
        method: 'post',
        url: connectNode + 'admin/assessment/chk_scid_status',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: this.props.match.params.pid,
          timepoint: this.props.match.params.timepoint,
        }
      })
      .then((result) => {
        //console.log(result['data'])
        if(result['data'] === 0) {
          this.setState({
            message: 'Something went wrong.',
          })
        } else {
          //console.log('step: '+result['data'].mini_step)
          if(result['data'].scid_step === 'complete') {
            this.props.history.push('/admin/assessments/scid_result/'+this.props.match.params.pid+'/'+this.props.match.params.timepoint)
          } else if(result['data'].scid_step !== 'scid2') {
            this.getData('new')
          } else {
            this.setState({
              appt_id: result['data'].id,
            })
          }
        }
      })
    }
  }

  getData = (edit_status) => {
    var get_edit_status = false
    if(edit_status == 'edit') {
      get_edit_status = true
    }
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/get_scid_data',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      if(result['data'].end_2_timestamp > 0) {
        this.setState({
          completed_status: true,
          edit_status: get_edit_status,
          c13: result['data'].c13,
          c13_mild: result['data'].c13_mild,
          c13_moderate: result['data'].c13_moderate,
          c13_severe: result['data'].c13_severe,
          c35: result['data'].c35,
          c35_mild: result['data'].c35_mild,
          c35_moderate: result['data'].c35_moderate,
          c35_severe: result['data'].c35_severe,
          d16: result['data'].d16,
          d22: result['data'].d22,
          d28: result['data'].d28,
          d37: result['data'].d37,
        })
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleNext = (page) => {
    window.location.href = "/admin/assessments/"+page+"/"+this.props.match.params.pid+"/-1"
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    var timepoint = this.props.match.params.timepoint
    this.props.onLoadLocation('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
    this.props.history.push('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)

    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/update_scid2',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        user: this.state,
        pid: pid,
        timepoint: timepoint,
      }
    })
    .then((result) => {
      if(result['data'] === this.state.next_step) {
        this.props.onLoadLocation('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
        this.props.history.push('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
      }
    })
  }

  handleEdit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    var timepoint = this.props.match.params.timepoint
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/edit_scid2',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        user: this.state,
        pid: pid,
        task: 'edit',
      }
    })
    .then((result) => {
      if(result['data'] == pid) {
        this.props.onLoadLocation('/admin/assessments/scid_result/'+pid+'/-1', 'Edit successfully!')
        this.props.history.push('/admin/assessments/scid_result/'+pid+'/-1')
      }
    })
  }

  render() {
    return (
      <div className="assm_mini box_border4">
        { //this.state.appt_id > 0 &&
        <div className="row">
          <div className="small-12 columns headerPage1">
            QuickSCID5: Intake Appointment
          </div>
          <StepperSCID activeStep={1} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />
        </div>}
        {/*this.props.match.params.task == 'edit' &&
        <div className="row">
          <div className="small-12 columns headerPage1">
            MINI Summary Sheet: Intake Appointment
          </div>
        </div>*/}
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>}
        { //(this.state.appt_id > 0 || this.props.match.params.task == 'edit') &&
          <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="small-12 columns box1">
              <div className="row show-for-medium bold">
                <div className="medium-9 columns">&nbsp;</div>
                {scid_ynmb.map(each =>
                  each.value > 0 &&
                  <div className="medium-1 columns headerResize1" key={'sch'+each.label}>
                    {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line1">&nbsp;</div>
                </div>
              </div>
              <div className="row" >
                <div className="small-12 medium-9 columns">
                  C13. Alcohol Use Disorder (past year)
                </div>
                {scid_ynmb.map(each =>
                  each.value > 0 &&
                  <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                    <input type="radio" name="c13" value={each.value}
                    checked={this.state['c13'] == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span className="show-for-small-only">{each.label}</span>
                  </div>)}
              </div>
              <div className="row" >
                <div className="small-12 medium-1 columns">&nbsp;</div>
                <div className="small-12 medium-4 columns">Mild Alcohol Use Disorder</div>
                {scid_yesno.map(each =>
                  each.value > 0 &&
                  <div className="small-12 medium-2 columns align_center" key={'sc'+each.label}>
                    <input type="radio" name="c13_mild" value={each.value}
                    checked={this.state['c13_mild'] == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span >{each.label}</span>
                  </div>)}
                <div className="small-12 medium-3 columns">&nbsp;</div>
              </div>
              <div className="row" >
                <div className="small-12 medium-1 columns">&nbsp;</div>
                <div className="small-12 medium-4 columns">Moderate Alcohol Use Disorder</div>
                {scid_yesno.map(each =>
                  each.value > 0 &&
                  <div className="small-12 medium-2 columns align_center" key={'sc'+each.label}>
                    <input type="radio" name="c13_moderate" value={each.value}
                    checked={this.state['c13_moderate'] == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span >{each.label}</span>
                  </div>)}
                <div className="small-12 medium-3 columns">&nbsp;</div>
              </div>
              <div className="row" >
                <div className="small-12 medium-1 columns">&nbsp;</div>
                <div className="small-12 medium-4 columns">Severe Alcohol Use Disorder</div>
                {scid_yesno.map(each =>
                  each.value > 0 &&
                  <div className="small-12 medium-2 columns align_center" key={'sc'+each.label}>
                    <input type="radio" name="c13_severe" value={each.value}
                    checked={this.state['c13_severe'] == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span >{each.label}</span>
                  </div>)}
                <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>
              </div>
              <div className="row" >
                <div className="small-12 medium-9 columns">
                  C35. Nonalcohol Substance Use Disorder (past year)
                </div>
                {scid_ynmb.map(each =>
                  each.value > 0 &&
                  <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                    <input type="radio" name="c35" value={each.value}
                    checked={this.state['c35'] == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span className="show-for-small-only">{each.label}</span>
                  </div>)}
              </div>
              <div className="row" >
                <div className="small-12 medium-1 columns">&nbsp;</div>
                <div className="small-12 medium-5 columns">Mild Nonalcohol Substance Use Disorder</div>
                {scid_yesno.map(each =>
                  each.value > 0 &&
                  <div className="small-12 medium-2 columns align_center" key={'sc'+each.label}>
                    <input type="radio" name="c35_mild" value={each.value}
                    checked={this.state['c35_mild'] == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span >{each.label}</span>
                  </div>)}
                <div className="small-12 medium-2 columns">&nbsp;</div>
              </div>
              <div className="row" >
                <div className="small-12 medium-1 columns">&nbsp;</div>
                <div className="small-12 medium-5 columns">Moderate Nonalcohol Substance Use Disorder</div>
                {scid_yesno.map(each =>
                  each.value > 0 &&
                  <div className="small-12 medium-2 columns align_center" key={'sc'+each.label}>
                    <input type="radio" name="c35_moderate" value={each.value}
                    checked={this.state['c35_moderate'] == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span >{each.label}</span>
                  </div>)}
                <div className="small-12 medium-5columns">&nbsp;</div>
              </div>
              <div className="row" >
                <div className="small-12 medium-1 columns">&nbsp;</div>
                <div className="small-12 medium-5 columns">Severe Nonalcohol Substance Use Disorder</div>
                {scid_yesno.map(each =>
                  each.value > 0 &&
                  <div className="small-12 medium-2 columns align_center" key={'sc'+each.label}>
                    <input type="radio" name="c35_severe" value={each.value}
                    checked={this.state['c35_severe'] == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span >{each.label}</span>
                  </div>)}
                <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>
              </div>
            </div>

            <div className="small-12 columns box1 show_label">
              <div className="row show-for-medium bold">
                <div className="medium-9 columns">&nbsp;</div>
                {scid_ynmb.map(each =>
                  each.value > 0 &&
                  <div className="medium-1 columns headerResize1" key={'sch'+each.label}>
                    {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line1">&nbsp;</div>
                </div>
              </div>
              <div className="row" >
                <div className="small-12 medium-9 columns">
                  <p>D16. Panic Disorder (current)</p>
                  <p>After any of these attacks, for at least a month, were you concerned that
                  you might have another attack or worried that you would have a heart attack or
                  worried that you would lose control or go crazy?</p>
                  <p>After any of these attacks, for at least a month, did you do anything differently
                  because of the attacks, like avoiding certain places or not going out alone or avoiding
                  certain activities like exercise or always making sure you're near a bathroom or exit?</p>
                </div>
                {scid_ynmb.map(each =>
                  each.value > 0 &&
                  <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                    <input type="radio" name="d16" value={each.value}
                    checked={this.state['d16'] == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span className="show-for-small-only">{each.label}</span>
                  </div>)}
              </div>
              <div className="row">
                <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>
              </div>
              <div className="row" >
                <div className="small-12 medium-9 columns">
                  <p>D22. Agoraphobia (current)</p>
                  <p>Has your fear or avoidance of these situations affected your life in important ways?<br />
                  Are you very bothered by the fact that youare afraid of these situations?</p>
                </div>
                {scid_ynmb.map(each =>
                  each.value > 0 &&
                  <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                    <input type="radio" name="d22" value={each.value}
                    checked={this.state['d22'] == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span className="show-for-small-only">{each.label}</span>
                  </div>)}
              </div>
              <div className="row">
                <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>
              </div>
              <div className="row" >
                <div className="small-12 medium-9 columns">
                  <p>D28. Social Anxiety Disorder (current)</p>
                  <p>Has your fear or avoidance of (FEARED SOCIAL OR PERFORMANCE SITUATION[S])
                  affected your life in important ways?<br />
                  Are you very bothered by the fact that you are afraid of
                  (FEARED SOCIAL OR PERFORMANCE SITUATION[S])?</p>
                </div>
                {scid_ynmb.map(each =>
                  each.value > 0 &&
                  <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                    <input type="radio" name="d28" value={each.value}
                    checked={this.state['d28'] == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span className="show-for-small-only">{each.label}</span>
                  </div>)}
              </div>
              <div className="row">
                <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>
              </div>
              <div className="row" >
                <div className="small-12 medium-9 columns">
                  <p>D37. Generalized Anxiety Disorder (current)</p>
                  <p>Have your anxiety and worry affected your life in important ways?<br />
                  Are you very bothered by the fact that you are anxious and worried most of the time?</p>
                </div>
                {scid_ynmb.map(each =>
                  each.value > 0 &&
                  <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                    <input type="radio" name="d37" value={each.value}
                    checked={this.state['d37'] == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span className="show-for-small-only">{each.label}</span>
                  </div>)}
              </div>

            </div>

          <div className="small-12 columns align_right">
            {!this.state.completed_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" className="submit_1">
                Submit
            </Button>}
            {this.state.completed_status && !this.state.edit_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={(e) => this.handleNext('scid3')} className="submit_1">
                Next
            </Button>}
            {this.state.edit_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleEdit} className="submit_1">
                Submit
            </Button>}
          </div>
        </div>
        </form>
        }

    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Scid2)
